import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";
import RatesCompRec from "../components/RatesCompRec";

function Rates() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Rates">
      <div className="w-full">
        <TransparentHeader
          headertitle="Rates"
          Subheader="Rates"
          bgimg={`${rpdata?.gallery?.[16]}`}
        />
        <div className="mainMenu">
          <div className="py-40 w-full md:w-4/5 mx-auto relative">
            <div className="py-10">
              <h2 className="text-blue-600 text-center">
                When you request a taxi to pick you up at the airport, O'Hare and Midway, these are the areas where we pick you up for your convenience:
              </h2>
              <p className=" text-center text-black text-lg font-medium">
                O'HARE Terminal 1: Door 1G, lower level, center island. Terminal 2: Door 2E lower level, center island. Terminal 3: Door 3G lower level, center island. Terminal 5: Door 5E, outside the door. MIDWAY Door 1, lower level, center island.
              </p>
            </div>
            <RatesCompRec />
          </div>
        </div>
      </div>
    </BaseLayout>
  );
}
export default Rates;
